jQuery(document).ready(function($){
	// $('.dropdown-toggle').click(function(event){
    //     event.preventDefault();
    //     jQuery(this).parent().toggleClass("open");
    // });
    
    $('.carousel-post').owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        responsive:{
            0:{
                items:1
            },
            600:{
                items:3
            },
            1000:{
                items:4
            }
        },
        navText: ['<img src="' + baseUrl + '/images/carousel/left.png" />', '<img src="' + baseUrl + '/images/carousel/right.png" />'],
    });

    // $('.responsive-descargas>.vc_column-inner>.wpb_wrapper').owlCarousel({
    //     items:1,
    //     loop: true,
    //     margin: 10,
    //     nav: true,
    //     dots: false,    
    //     navText: ['<img src="' + baseUrl + '/images/carousel/left-white.png" />', '<img src="' + baseUrl + '/images/carousel/right-white.png" />'],
    // });

    jQuery(document).ready(function(){
        jQuery('#responsive-blocks').owlCarousel({
            loop:true,
            nav:false,
            dots:true,
            items:1
        })
    });

    jQuery(document).ready(function(){
        jQuery('#responsive-descargas').owlCarousel({
            loop:true,
            nav:false,
            dots:true,
            items:1
        })
    });

    $('.carouselpackaging>.vc_column-inner>.wpb_wrapper').owlCarousel({
        items:3,
        loop: true,
        margin: 10,
        nav: true,
        dots: false, 
        responsive:{
            0:{
                items:1
            },
            600:{
                items:1
            }
        },   
        navText: ['<img src="' + baseUrl + '/images/carousel/left.png" />', '<img src="' + baseUrl + '/images/carousel/right.png" />'],
    });


    $('.descargaregistro').click(function(event){
        if(!$('body').hasClass('logged-in')){
            event.preventDefault();
            $('#fondo').fadeIn(300);
        }
    });

    $('.registration').click(function(event){
        event.preventDefault();
        $('#fondo').fadeIn(300);
        gtag('event', 'clic', {
            'event_category': 'boton_registro',
            'event_label': 'registrate'
        });
    });
    
    $('#cerrar-pop-up').click(function(){
        $('#fondo').fadeOut(300); 
    });


    $('#descargaguia').click(function () {
        gtag('event', 'descarga', {
            'event_category': 'boton_salesfolder_delivery',
            'event_label': 'salesfolder'
        });
    });
    $('#cocaburguer').click(function () {
        gtag('event', 'descarga', {
            'event_category': 'boton_foto_combo',
            'event_label': 'hamgurguesa cocacola zero'
        });
    });
    $('#spritepizza').click(function () {
        gtag('event', 'descarga', {
            'event_category': 'boton_foto_combo',
            'event_label': 'pizza sprite'
        });
    });
    $('#nesteaperrito').click(function () {
        gtag('event', 'descarga', {
            'event_category': 'boton_foto_combo',
            'event_label': 'nestea perrito'
        });
    });
    $('#cocatacos').click(function () {
        gtag('event', 'descarga', {
            'event_category': 'boton_foto_combo',
            'event_label': 'tacos cocacolazero'
        });
    });
    $('#fantaplato').click(function () {
        gtag('event', 'descarga', {
            'event_category': 'boton_foto_combo',
            'event_label': 'tallarines fanta naranja'
        });
    });
    $('#coca').click(function () {
        gtag('event', 'descarga', {
            'event_category': 'boton_foto_botella',
            'event_label': 'coca cola original'
        });
    });
    $('#aquanaranja').click(function () {
        gtag('event', 'descarga', {
            'event_category': 'boton_foto_botella',
            'event_label': 'aquarius naranja'
        });
    });
    $('#sprite').click(function () {
        gtag('event', 'descarga', {
            'event_category': 'boton_foto_botella',
            'event_label': 'sprite'
        });
    });
    $('#monster').click(function () {
        gtag('event', 'descarga', {
            'event_category': 'boton_foto_botella',
            'event_label': 'monster'
        });
    });
    $('#royal').click(function () {
        gtag('event', 'descarga', {
            'event_category': 'boton_foto_botella',
            'event_label': 'royal bliss tonica water'
        });
    });
    $('#cocablue').click(function () {
        gtag('event', 'descarga', {
            'event_category': 'boton_foto_botella',
            'event_label': 'coca cola original fondo azul'
        });
    });
    $('#nesteablue').click(function () {
        gtag('event', 'descarga', {
            'event_category': 'boton_foto_botella',
            'event_label': 'nesteafondo azul'
        });
    });
    $('#monsterblue').click(function () {
        gtag('event', 'descarga', {
            'event_category': 'boton_foto_botella',
            'event_label': 'monster fondo azul'
        });
    });
    $('#monsterultra').click(function () {
        gtag('event', 'descarga', {
            'event_category': 'boton_foto_botella',
            'event_label': 'monster ultra fondo azul'
        });
    });
    $('.adaptamenu').click(function () {
        gtag('event', 'descarga', {
            'event_category': 'boton_foto_botella',
            'event_label': 'monster ultra fondo azul'
        });
    });
    $('.adaptamenudesc').click(function () {
        gtag('event', 'clic', {
            'event_category': 'boton_adapta_tu_menu',
            'event_label': 'texto descripcion'
        });
    });
    $('.adaptamenuimg').click(function () {
        gtag('event', 'clic', {
            'event_category': 'boton_adapta_tu_menu',
            'event_label': 'imagen'
        });
    });
    $('.desckpack').click(function () {
        gtag('event', 'descarga', {
            'event_category': 'boton_guia_packaging',
            'event_label': 'guia packaging'
        });
    });
    $('.blogs').click(function () {
        gtag('event', 'clic', {
            'event_category': 'boton_ver_mas',
            'event_label': 'blogs'
        });
    });

    
});



WebFontConfig = {
    google: {
    families: [
        'Raleway:100,100italic,200,200italic,300,300italic,400,400italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic',
        'Syne:100,100italic,200,200italic,300,300italic,400,400italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic',
        ]
    }
};

(function(d) {
    var wf = d.createElement('script'), s = d.scripts[0];
    wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js';
    s.parentNode.insertBefore(wf, s);
})(document);

