jQuery(document).ready(function () {
    if (jQuery('.slider-home')) {
        jQuery('.slider-home').owlCarousel({
            center: true,
            items: 1,
            loop: true,
            margin: 0,
            nav: true,
            autoplay: true,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            smartSpeed: 700,
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 1
                },
                1024: {
                    items: 1
                }
            },
        });
    }

    if (jQuery('.slider-entrada')) {
        jQuery('.slider-entrada').owlCarousel({
            center: true,
            items: 1,
            loop: false,
            margin: 0,
            nav: true,
            autoplay: false,
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 1
                },
                1024: {
                    items: 1
                }
            },
        });
    }

    if (jQuery('.carousel-slider')) {
        jQuery('.carousel-slider').owlCarousel({
            center: true,
            items: 3,
            loop: true,
            margin: 0,
            nav: true,
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 1
                },
                1024: {
                    items: 3
                }
            },
            navText: ['<img src="' + baseUrl + '/dist/images/abspag_icon_left.png" />', '<img src="' + baseUrl + '/dist/images/abspag_icon_right.png" />'],
        });
    }

    if (jQuery('.carousel-entrevistas')) {
        jQuery('.carousel-entrevistas').owlCarousel({
            items: 4,
            loop: true,
            margin: 15,
            nav: true,
            responsive: {
                0: {
                    items: 1
                },
                600: {
                    items: 4
                }
            },
            navText: ['<img src="' + baseUrl + '/dist/images/abspag_icon_left.png" />', '<img src="' + baseUrl + '/dist/images/abspag_icon_right.png" />'],
        });
    }

    if (jQuery('.carousel-collaborators')) {
        jQuery('.carousel-collaborators').owlCarousel({
            items: 6,
            loop: true,
            margin: 15,
            nav: true,
            responsive: {
                0: {
                    items: 2
                },
                600: {
                    items: 4
                }
            },
            navText: ['<img src="' + baseUrl + '/dist/images/abspag_icon_left.png" />', '<img src="' + baseUrl + '/dist/images/abspag_icon_right.png" />'],
        });
    }

    if (jQuery('.carousel-destacadoshome')) {
        jQuery('.carousel-destacadoshome').owlCarousel({
            items: 6,
            loop: true,
            margin: 15,
            nav: true,
            responsive: {
                0: {
                    items: 2
                },
                600: {
                    items: 4
                }
            },
            navText: ['<img src="' + baseUrl + '/dist/images/abspag_icon_left.png" />', '<img src="' + baseUrl + '/dist/images/abspag_icon_right.png" />'],
        });
    }

    if (jQuery('.carousel-collaborators-sidebar')) {
        jQuery('.carousel-collaborators-sidebar').owlCarousel({
            items: 6,
            loop: true,
            margin: 15,
            nav: true,
            responsive: {
                0: {
                    items: 6
                },
                600: {
                    items: 6
                }
            },
            navText: ['<img src="' + baseUrl + '/dist/images/abspag_icon_left.png" />', '<img src="' + baseUrl + '/dist/images/abspag_icon_right.png" />'],
        });
    }
    jQuery('.carousel-novedades').owlCarousel({
        items: 4,
        loop: true,
        margin: 30,
        dots: false,
        nav: true,
        responsive: {
            0: {
                items: 1
            },
            480: {
                items: 2
            },
            768: {
                items: 2
            },
            1024: {
                items: 4
            }
        },
        navText: ['<img src="' + baseUrl + '/dist/images/abspag_icon_left.png" />', '<img src="' + baseUrl + '/dist/images/abspag_icon_right.png" />'],
    });


    jQuery('.owl-carousel-last').owlCarousel({
        center: true,
        items: 1,
        loop: false,
        margin: 0,
        dots: false,
        nav: true,
        navText: ['<img src="' + baseUrl + '/dist/images/abspag_icon_left.png" />', '<img src="' + baseUrl + '/dist/images/abspag_icon_right.png" />'],
    });
    jQuery('#owl-carousel-all').owlCarousel({
        center: true,
        items: 1,
        loop: false,
        margin: 0,
        dots: false,
        nav: true,
        navText: ['<img src="' + baseUrl + '/dist/images/abspag_icon_left.png" />', '<img src="' + baseUrl + '/dist/images/abspag_icon_right.png" />'],
    });
    if (arrayID) {
        jQuery.each(JSON.parse(arrayID), function (index, value) {
            jQuery("#owl-carousel-" + value).owlCarousel({
                items: 4,
                loop: false,
                margin: 15,
                dots: false,
                nav: true,
                responsive: {
                    0: {
                        items: 1
                    },
                    480: {
                        items: 2
                    },
                    768: {
                        items: 3
                    },
                    1024: {
                        items: 4
                    }
                },
                navText: ['<img src="' + baseUrl + '/dist/images/abspag_icon_left.png" />', '<img src="' + baseUrl + '/dist/images/abspag_icon_right.png" />'],
            });
        });
    }
    if (jQuery('.carousel-comunicacion2')) {
        jQuery('.carousel-comunicacion2').owlCarousel({
            items: 4,
            loop: true,
            margin: 15,
            nav: true,
            responsive: {
                0: {
                    items: 1
                },
                600: {
                    items: 4
                }
            },
            navText: ['<img src="' + baseUrl + '/dist/images/abspag_icon_left.png" />', '<img src="' + baseUrl + '/dist/images/abspag_icon_right.png" />'],
        });
    }
    if (jQuery('.carousel-google')) {
        jQuery('.carousel-google').owlCarousel({
            items: 6,
            loop: true,
            margin: 40,
            nav: true,
            dots: false,
            responsive: {
                0: {
                    items: 1
                },
                600: {
                    items: 4
                },
                1280: {
                    items: 6
                }
            },
            navText: ['<img src="' + baseUrl + '/dist/images/left_red.png" />', '<img src="' + baseUrl + '/dist/images/right_red.png" />'],
        });
    }
    if (jQuery('.carousel-google-2')) {
        jQuery('.carousel-google-2').owlCarousel({
            items: 3,
            loop: true,
            margin: 40,
            nav: true,
            dots: false,
            responsive: {
                0: {
                    items: 1
                },
                600: {
                    items: 3
                },
                1280: {
                    items: 3
                }
            },
            navText: ['<img src="' + baseUrl + '/dist/images/left_red.png" />', '<img src="' + baseUrl + '/dist/images/right_red.png" />'],
        });
    }
    if (jQuery('.comopuedohacercrecer')) {
        jQuery('.comopuedohacercrecer').owlCarousel({
            items: 4,
            loop: true,
            margin: 40,
            nav: true,
            dots: false,
            responsive: {
                0: {
                    items: 1
                },
                600: {
                    items: 4
                },
                1280: {
                    items: 4
                }
            },
            navText: ['<img src="' + baseUrl + '/dist/images/left_red.png" />', '<img src="' + baseUrl + '/dist/images/right_red.png" />'],
        });
    }
    if (jQuery('.digitaliza-carousel')) {
        jQuery('.digitaliza-carousel>.vc_column-inner>.wpb_wrapper').owlCarousel({
            items: 1,
            loop: true,
            margin: 0,
            nav: true,
            dots: true,
            navText: ['<img src="' + baseUrl + '/dist/images/carouseles/flecha_roja_left.png" />', '<img src="' + baseUrl + '/dist/images/carouseles/flecha_roja_right.png" />'],
        });
    }

    // Mixología
    if (jQuery('.carousel-mixo-recetas')) {
        jQuery(".carousel-mixo-recetas").owlCarousel({
            items: 4,
            loop: true,
            margin: 15,
            dots: true,
            nav: true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive: {
                0: {
                    items: 1
                },
                800: {
                    items: 2
                },
                900: {
                    items: 3
                },
                1200: {
                    items: 4
                }
            }
        });
    }

    if (jQuery('.carousel-mixo-images')) {
        jQuery('.carousel-mixo-images').owlCarousel({
            center: true,
            margin: -60,
            loop: true,
            dots: false,
            nav: true,
            items: 4,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive: {
                0: {
                    items: 2,
                    dots: true
                },
                990: {
                    items: 3,
                    dots: true
                },
                1200: {
                    items: 4,
                }
            },
            onInitialized: coverFlowEfx,
            onTranslate: coverFlowEfx,
        });
    }

    if (jQuery('#aperitivo_carousel')) {
        jQuery('#aperitivo_carousel').owlCarousel({
            items:1,
            loop:true,
            autoWidth:true,
            center:true,
            margin:10,
            autoplayHoverPause:true,
            dots: false,
            nav: true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
        });
    }

    if (jQuery('#tardeo_carousel')) {
        jQuery('#tardeo_carousel').owlCarousel({
            items:1,
            loop:true,
            autoWidth:true,
            center:true,
            margin:10,
            autoplayHoverPause:true,
            dots: false,
            nav: true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
        });
    }

    if (jQuery('#noche_carousel')) {
        jQuery('#noche_carousel').owlCarousel({
            items:1,
            loop:true,
            autoWidth:true,
            center:true,
            margin:10,
            autoplayHoverPause:true,
            dots: false,
            nav: true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
        });
    }

    // Formación Hostelera

    if (jQuery('.owl-carousel-forma-sal')) {
        jQuery(".owl-carousel-forma-sal").owlCarousel({
            items: 3,
            loop: true,
            margin: 24,
            nav: true,
            navText: ['<', '>'],
            responsive: {
                0: {
                    items: 3
                },
                600: {
                    items: 3
                },
                1000: {
                    items: 3
                }
            }
        });
    }

    if (jQuery('.carousel-relateds-forma')) {
        jQuery('.carousel-relateds-forma').owlCarousel({
            loop: true,
            margin: 10,
            nav: true,
            responsive: {
                0: {
                    items: 1
                },
                600: {
                    items: 3
                },
                1000: {
                    items: 4
                }
            },
            navText: ['<img src="' + baseUrl + '/images/carousel/left.png" />', '<img src="' + baseUrl + '/images/carousel/right.png" />'],
        });
    }

    function coverFlowEfx(e) {
        // if (jQuery('.owl-dots')) {
        //     jQuery('.owl-dots').remove();
        // }
        idx = e.item.index;
        jQuery('.owl-item.big').removeClass('big');
        jQuery('.owl-item.medium').removeClass('medium');
        jQuery('.owl-item.mdright').removeClass('mdright');
        jQuery('.owl-item.mdleft').removeClass('mdleft');
        jQuery('.owl-item.smallRight').removeClass('smallRight');
        jQuery('.owl-item.smallLeft').removeClass('smallLeft');
        jQuery('.owl-item').eq(idx - 1).addClass('medium mdleft');
        jQuery('.owl-item').eq(idx).addClass('big');
        jQuery('.owl-item').eq(idx + 1).addClass('medium mdright');
        jQuery('.owl-item').eq(idx + 2).addClass('smallRight');
        jQuery('.owl-item').eq(idx - 2).addClass('smallLeft');
    }
    if (jQuery('.carousel-mixo-noticias')) {
        jQuery(".carousel-mixo-noticias").owlCarousel({
            items: 1,
            loop: true,
            margin: 20,
            dots: true,
            nav: true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive: {
                0: {
                    items: 1
                },
                800: {
                    items: 1
                }
            }
        });
    }

    if (jQuery('.carousel-mixo-noticias-mobile')) {
        jQuery(".carousel-mixo-noticias-mobile").owlCarousel({
            items: 1,
            loop: true,
            dots: true,
            nav: true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
        });
    }

    jQuery(".post_1 .post-module-content").addClass('equalheight-row');
    jQuery(".post_6 .post-module-content").addClass('equalheight-row');
    jQuery(".post-module-content").css('height', 'auto');
    jQuery(".linkimage").on("mouseover", function (e) {
        e.preventDefault();
        jQuery(this).find('.viewmore-img').attr('src', baseUrl + "/dist/images/abspag_icon_view_more_hover.png");
    });
    jQuery(".linkimage").on("mouseout", function (e) {
        e.preventDefault();
        jQuery(this).find('.viewmore-img').attr('src', baseUrl + "/dist/images/abspag_icon_view_more.png");
    });

    jQuery(window).on("resize", function (e) {
        checkScreenSize();
    });

    checkScreenSize();

    jQuery('.descubrir').click(function (e) {
        e.preventDefault();
        jQuery(this).parent().parent().parent().parent().find('.descubirhidden').toggleClass('hidden', 2000);
    });

    jQuery('#regprof form').attr('id', 'idregprof');
    jQuery("form[name='registration']").validate({
        rules: {
            email: {
                required: true,
                email: true
            },
            provincia: "required",
            ocupacion: "required",
            mayor: "required",
            privacidad: "required"
        },
        messages: {
            firstname: "Por favor, introduzca una provincia",
            ocupacion: "Por favor, seleccione una ocupación",
            mayor: "Por favor, seleccione una opción",
            privacidad: "Campo requerido",
            email: "Por favor, introduzca una dirección de email válida"
        },
        submitHandler: function (form) {
            form.submit();
        }
    });

});
