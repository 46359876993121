/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {

        // All pages
        // -----------------------------------------------------------------------
        'common': {
            init: function () {
                // JavaScript to be fired on all pages

                // Menu mobile
                (function menuMobile() {
                    var $item = $('.menu-item-has-children');
                    var $link = $('.menu-item-has-children > a');
                    var $icon = '<span class="arrow"><i class="fa fa-angle-down"></i></span>';
                    var $submenu = $item.find('.sub-menu');

                    $submenu.hide();
                    $link.append($icon);

                    var $arrow = $link.find('.arrow');

                    $arrow.on('click', function (event) {
                        event.preventDefault();
                        $(this).closest('li').find('.sub-menu').slideToggle();
                        $(this).find('i').toggleClass('fa-angle-down fa-angle-up');
                        $(this).parent().parent().toggleClass('opened');
                    });
                })();

                // ScrollReveal
                // https://github.com/jlmakes/scrollreveal.js
                // Quitar clase .sr si ScrollReveal no existe
                // Así se pueden ver los elementos ocultos (.reveal) con
                // .sr .reveal { visibility: hidden; }
                window.sr = new ScrollReveal();
                if (!sr.isSupported()) {
                    $('body').removeClass('sr');
                }

                // Enlaces externos
                $('a[rel*=external]').click(function () {
                    window.open(this.href);
                    return false;
                });

                // FitVids
                $('.entry-content-asset').fitVids();

                // Selects
                $('.select').dropdown(
                    {
                        'autoinit': '.select',
                        // 'dropdownClass': 'my-dropdown',
                        'optionClass': 'withripple',
                        'callback': function ($dropdown) {
                            // $dropdown.fadeIn("slow");
                            $dropdown.find('ul').addClass('dropdownjs-ul');
                        }
                    }
                );

                // Init
                $.material.init({ validate: false });

                // Tooltips
                $('[data-toggle="tooltip"]').tooltip();

                // Dropdowns
                // Mantener abiertos
                $('.dropdown.keep-open .dropdown-toggle').on('click', function (event) {
                    $(this).parent().toggleClass('open');
                });

                $('body').on('click', function (e) {
                    if ((!$('.dropdown.keep-open').is(e.target)) && ($('.dropdown.keep-open').has(e.target).length === 0) && ($('.open').has(e.target).length === 0)) {
                        $('.dropdown.keep-open').removeClass('open');
                    }
                });

                $('.resend-email-link').click(function () {
                    var email = $('.resend-email-link-email').val();
                    var coupon = $('.resend-email-link-coupon').val();
                    var userid = $('.resend-email-link-userid').val();

                    if ((email === null) || (email === "null")) {
                        return;
                    }

                    jQuery.ajax({
                        method: 'get',
                        url: window.location.origin + '/wp-json/dnoise/v1/resendemailregister',
                        data: {
                            user_email: email,
                            user_coupon: coupon,
                            user_id: userid
                        },
                        success: function (response) {

                            if (response === 1) {
                                swal({
                                    title: 'BartalentLab',
                                    text: 'Hemos vuelto a mandarte el mail para que verifiques tu cuenta. Si aún así no lo has recibido, ponte en contacto con nosotros en <a href="mailto:info@bartalentlab.com" title="Contactar por correo electrónico">info@bartalentlab.com</a>',
                                    html: true,
                                    type: 'success',
                                    icon: 'success',
                                    confirmButtonText: 'Aceptar'
                                });
                            }
                            else {
                                swal({
                                    title: '¡Lo sentimos!',
                                    text: 'Existe algún tipo de problema y no se ha podido enviar el correo de confirmación, por favor, espera un rato y vuelve a intentarlo',
                                    html: true,
                                    type: 'error',
                                    confirmButtonText: 'Aceptar'
                                });
                            }

                        },
                        error: function (response) {

                            swal({
                                title: '¡Lo sentimos!',
                                text: 'Existe algún tipo de problema y no se ha podido enviar el correo de confirmación, por favor, espera un rato y vuelve a intentarlo',
                                html: true,
                                type: 'error',
                                confirmButtonText: 'Aceptar'
                            });

                        }
                    });
                });

                var $menuDropdown = $('.dropdown-menu[aria-labelledby="navbar-desktop"]');
                if ($menuDropdown.find('.error').length !== 0) {
                    $($menuDropdown).toggle();
                    $('#navbar-desktop').attr('aria-expanded', true);
                }

                // Top bar login dropdown: abrir si hay errores de login
                var $loginDropdown = $('.dropdown-menu[aria-labelledby="dLogin"]');

                if ($loginDropdown.find('.error').length !== 0) {
                    $($loginDropdown).toggle();
                    $('#dLogin').attr('aria-expanded', true);
                }

                // Search form icon submit
                $('.search-form .fa-search').click(function () {
                    $form = $(this).closest('form');
                    $form.find('.search-field').focus();
                    // $form.validate();
                    if ($form.valid()) {
                        $form.submit();
                    }
                });

                // Slider Main
                $('.slider-main').slick({
                    // slidesToShow: 1,
                    // slidesToScroll: 1,
                    // arrows: true,
                    dots: true,
                    autoplay: true,
                    autoplaySpeed: 4000,
                    // speed: 1000,
                    // fade: true,
                    useTransform: true,
                    cssEase: 'cubic-bezier(.5, 0, 0, 1)', // custom
                    // pauseOnHover: false,
                    // focusOnSelect: false,
                    // lazyLoad: 'ondemand',
                    responsive: [
                        {
                            breakpoint: 767, // max-width 767px / screen-xs-max
                            settings: {
                                arrows: false
                            }
                        },
                    ]
                });

                $('.slider-academy-home').slick({
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    arrows: true,
                    dots: true,
                    autoplay: true,
                    autoplaySpeed: 6000,
                    useTransform: true,
                    cssEase: 'cubic-bezier(.5, 0, 0, 1)', // custom
                    responsive: [
                        {
                            breakpoint: 440, // max-width 440px
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                vertical: true
                            }
                        }
                    ],
                    customPaging: function (slider, i) {
                        var i_str = parseInt(i) + 1;
                        i_str = "" + i_str;
                        var i_str_save = i_str;
                        if (i_str.length === 1) { i_str = "0" + i_str; }

                        var class_active = "";
                        if (i_str_save === "1") {
                            class_active = " link-pagination-carrousel-academy-active";
                        }

                        return '<a id="item_pgn_carr_academy_' + i_str_save + '" class="pager_slider-academy-home_item' + class_active + '">&nbsp;' + i_str + '&nbsp;</a>';
                    }
                });

                $('.slider-academy-home').on('afterChange', function (event, slick, currentSlide) {

                    var item_actual = currentSlide;
                    var item_total = slick.slideCount;
                    var items_por_pagina = 3; // PC

                    if (screen.width <= 440) {
                        items_por_pagina = 1;  // Mobile
                    }

                    var num_paginas = (item_total / items_por_pagina);
                    if (items_por_pagina !== 1) { num_paginas++; }
                    num_paginas = Math.trunc(num_paginas);

                    var pag_actual = Math.trunc(item_actual / items_por_pagina) + 1;

                    // console.log("diapos actual: "+currentSlide);
                    // console.log("items: "+item_total);
                    // console.log("items por pagina: "+items_por_pagina);
                    // console.log("numero de paginas: "+num_paginas);
                    // console.log("pagina actual: "+pag_actual);

                    $(".pager_slider-academy-home_item").removeClass("link-pagination-carrousel-academy-active");
                    $("#item_pgn_carr_academy_" + pag_actual).addClass("link-pagination-carrousel-academy-active");
                });

                $('.slider-collaborators-home').slick({
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    arrows: true,
                    dots: true,
                    autoplay: true,
                    autoplaySpeed: 6000,
                    useTransform: true,
                    cssEase: 'cubic-bezier(.5, 0, 0, 1)', // custom
                    // speed: 1000,
                    // fade: true,
                    // pauseOnHover: false,
                    // focusOnSelect: false,
                    // lazyLoad: 'ondemand',
                    responsive: [
                        {
                            breakpoint: 440, // max-width 440px
                            settings: {
                                slidesToShow: 4,
                                slidesToScroll: 4,
                                vertical: true
                            }
                        }
                    ],
                    customPaging: function (slider, i) {
                        var i_str = parseInt(i) + 1;
                        i_str = "" + i_str;
                        var i_str_save = i_str;
                        if (i_str.length === 1) { i_str = "0" + i_str; }

                        var class_active = "";
                        if (i_str_save === "1") {
                            class_active = " link-pagination-carrousel-collaborators-active";
                        }

                        return '<a id="item_pgn_carr_collaborators_' + i_str_save + '" class="pager_slider-collaborators-home_item' + class_active + '">&nbsp;' + i_str + '&nbsp;</a>';
                    }
                });

                $('.slider-collaborators-home').on('afterChange', function (event, slick, currentSlide) {

                    var item_actual = currentSlide;
                    var item_total = slick.slideCount;
                    var items_por_pagina = 3; // PC

                    if (screen.width <= 440) {
                        items_por_pagina = 4;  // Mobile
                    }

                    var num_paginas = (item_total / items_por_pagina);
                    if (items_por_pagina !== 1) { num_paginas++; }
                    num_paginas = Math.trunc(num_paginas);

                    var pag_actual = Math.trunc(item_actual / items_por_pagina) + 1;

                    // console.log("diapos actual: "+currentSlide);
                    // console.log("items: "+item_total);
                    // console.log("items por pagina: "+items_por_pagina);
                    // console.log("numero de paginas: "+num_paginas);
                    // console.log("pagina actual: "+pag_actual);

                    $(".pager_slider-collaborators-home_item").removeClass("link-pagination-carrousel-collaborators-active");
                    $("#item_pgn_carr_collaborators_" + pag_actual).addClass("link-pagination-carrousel-collaborators-active");
                });

                $('.slider-collaborators-home .slick-slide').click(function (event) {
                    location.href = "/be-a-bartalent/colaboradores/";
                    event.preventDefault();
                    event.stopPropagation();
                    return false;
                });

                // Formulario de registro
                (function registrationActions() {

                    var $registerForm = $('#register-bartalent form[name="registerform"]');
                    var $bartalentAlta = $('#register-bartalent');

                    var $registerOneStepForm = $('#register-onestep form[name="registerform-onestep"]');
                    var $bartalentOneStepAlta = $('#register-onestep');

                    var $registerModalForm = $('#modal-register-bounce form[name="registerform"]');
                    var $registerModal = $('#modal-register-bounce');

                    // Scroll al formulario de registro si tiene errores de validación backend
                    $(window).load(function () {
                        if ($registerForm.find('.error').length !== 0) {
                            $('html, body').animate({
                                scrollTop: $bartalentAlta.offset().top
                            }, 2000);
                        }

                        if ($registerOneStepForm.find('.error').length !== 0) {
                            $('html, body').animate({
                                scrollTop: $bartalentOneStepAlta.offset().top
                            }, 2000);
                        }
                    });


                    // Abrir modal de registro si tiene errores de validación backend
                    $(window).load(function () {
                        if ($registerModalForm.find('.error').length !== 0) {
                            $registerModal.modal('show');
                        }
                    });

                })();


                // Scroll suave
                $('.smooth-scroller').click(function (e) {
                    e.preventDefault();
                    var el = $(this.getAttribute('href'));
                    var offs = el.offset();
                    $('html, body').stop().animate({ scrollTop: offs.top - 0 }, 600, 'easeInOutQuart');
                });

                // ScrollUp
                $(function () {
                    $.scrollUp({
                        scrollName: 'scrollUp', // Element ID
                        // scrollDistance: 300, // Distance from top/bottom before showing element (px)
                        // scrollFrom: 'top', // 'top' or 'bottom'
                        scrollSpeed: 600, // Speed back to top (ms)
                        easingType: 'easeInOutQuart', // Scroll to top easing (see http://easings.net/)
                        // animation: 'fade', // Fade, slide, none
                        // animationSpeed: 200, // Animation speed (ms)
                        // scrollTrigger: false, // Set a custom triggering element. Can be an HTML string or jQuery object
                        // scrollTarget: false, // Set a custom target element for scrolling to. Can be element or number
                        scrollText: '<i class="fa fa-fw fa-lg fa-chevron-up"></i>', // Text for element, can contain HTML
                        // scrollTitle: false, // Set a custom <a> title if required.
                        // scrollImg: false, // Set true to use image
                        // activeOverlay: false, // Set CSS color to display scrollUp active point, e.g '#00FFFF'
                        zIndex: 2147483647 // Z-Index for the overlay
                    });
                });


                // Ellipsis multilinea
                $(".ellipsis").dotdotdot({
                    ellipsis: '\u2026', // '... ',
                    // wrap: 'word',
                    // fallbackToLetter: true,
                    // after: null,
                    watch: 'window', // false,
                    // height: null,
                    // tolerance: 0,
                    // callback: function( isTruncated, orgContent ) {},
                    // lastCharacter: {
                    //   remove: [ ' ', ',', ';', '.', '!', '?' ],
                    //   noEllipsis : []
                    // }
                });

                // Equal heigh columns
                // https://github.com/liabru/jquery-match-height
                $('.equalheight').matchHeight({
                    byRow: false,
                    // property: 'height',
                    // target: null,
                    // remove: false
                });

                $('.equalheight-row').matchHeight({
                    // byRow: true,
                    // property: 'height',
                    // target: null,
                    // remove: false
                });

                $('.equalheight-post-module').matchHeight({
                    byRow: true,
                    target: $('.post-module-image')
                });

                // WPCF7 - Mover ajax-loader
                $('.ajax-loader').each(function () {
                    $(this).appendTo($(this).prev());
                });

                // Aceptar política en registro
                $('.checkbox-acceptance').click(function () {
                    if ($(this).is(':checked')) {
                        $(this).closest('form').find('.btn[type="submit"]').removeAttr('disabled');
                    } else {
                        $(this).closest('form').find('.btn[type="submit"]').attr('disabled', 'disabled');
                    }
                });

                // Enlace a modal en barra de cookies
                $('#button-modal-cookie').click(function (event) {
                    event.preventDefault();
                    $('#modal-politica-de-cookies').modal('show');
                });

                // Aceptar política de cookies en el modal
                $('#modal-politica-de-cookies #acceptance').click(function () {
                    $('#cookie_action_close_header').click();
                });

                // MyCRED Ranking points odometer
                (function odometerPoints() {
                    $('.user-points').each(function () {
                        var odo = new Odometer({
                            el: this,
                            value: 0,
                            format: '(.ddd)',
                            theme: 'ranking-points',
                            duration: 600
                        });

                        odo.render();
                    });
                })();

                // Top-Bar Total points
                (function topPoints() {
                    $el = $('.top-bar .user-points');
                    val = $el.data('points');
                    $el.text(val);
                })();

                // Edit profile points
                (function profilePoints() {
                    $el = $('.user-profile-points .user-points');
                    val = $el.data('points');
                    $el.text(val);
                })();

                // MyCRED Ranking progressbar
                (function doProgress() {
                    var $container = $('.myCRED-leaderboard');
                    var max = Math.log(parseInt($container.find('.first-item .user-points').data('points'))) / Math.log(2);

                    $container.find('li').each(function () {
                        var $pointsContainer = $(this).find('.user-points');
                        var $progressBar = $(this).find('.progress .progress-bar');
                        var points = $pointsContainer.data('points');
                        var val = Math.log(parseInt(points)) / Math.log(2);

                        if (typeof max === 'undefined' || typeof val === 'undefined') {
                            return false;
                        }

                        var real = parseInt(val / max * 100);

                        $(this).on('inview', function (event, isInView) {
                            if (isInView) {
                                $progressBar.css('width', real + '%');
                                $pointsContainer.text(points);
                            } else {
                                $progressBar.css('width', '0%');
                                $pointsContainer.text('0');
                            }
                        });
                    });
                })();

                // Browser password autofill floating label fix
                $(window).load(function () {
                    setTimeout(function () {
                        var $passInput = $('input[type="password"]:-webkit-autofill');
                        $passInput.closest('.form-group').removeClass('is-empty');
                    }, 500);
                });

                // WP Pro Quiz
                $(document).ajaxSuccess(function (event, xhr, settings) {
                    var response = xhr.responseJSON;
                    var quiz = $('.entry-quiz').data('quiz');
                    var $headerIncomplete = $('.entry-quiz-incomplete');
                    var $headerComplete = $('.entry-quiz-complete');
                    if (quiz && (quiz === response.quiz && response.complete === 'full')) {
                        $headerIncomplete.slideUp();
                        $headerComplete.slideDown();
                    }
                });

                // Menú principal active fixes
                if (
                    $('body').hasClass('post-type-archive-ccip_mix_noticia') ||
                    ($('body').hasClass('archive') && $('body').hasClass('tax-ccip_mix_noticia_categoria')) ||
                    $('body').hasClass('single-ccip_mix_noticia') ||
                    $('body').hasClass('post-type-archive-ccip_mix_video') ||
                    ($('body').hasClass('archive') && $('body').hasClass('tax-ccip_mix_video_categoria')) ||
                    $('body').hasClass('single-ccip_mix_video') ||
                    $('body').hasClass('post-type-archive-ccip_rb_mixer') ||
                    $('body').hasClass('single-ccip_rb_mixer') ||
                    $('body').hasClass('post-type-archive-ccip_rb_video') ||
                    $('body').hasClass('single-ccip_rb_video')
                ) {
                    $('#menu-menu-principal').find('.menu-mixologia').addClass('active');
                }

                // Page-Subnav Mixología/Royal Bliss active fixes
                if (
                    $('body').hasClass('post-type-archive-ccip_rb_mixer') ||
                    $('body').hasClass('single-ccip_rb_mixer') ||
                    $('body').hasClass('post-type-archive-ccip_rb_video') ||
                    $('body').hasClass('single-ccip_rb_video')
                ) {
                    $('#menu-mixologia').find('.menu-royal-bliss').addClass('active');
                }

                // Botón "No soy menor de edad"
                $('#cookie-remover').on('click', function (e) {
                    e.preventDefault();
                    $.removeCookie('user_is_adult', { path: '/' });
                    location.reload();
                });

                // Validaciones
                // ----------------------------------------------------------------------
                // Defaults a Español
                $.extend($.validator.messages, {
                    required: "Campo obligatorio.",
                    // remote: "Please fix this field.",
                    email: "Formato incorrecto.",
                    // url: "Please enter a valid URL.",
                    // date: "Please enter a valid date.",
                    // dateISO: "Please enter a valid date (ISO).",
                    // number: "Please enter a valid number.",
                    // digits: "Please enter only digits.",
                    // creditcard: "Please enter a valid credit card number.",
                    // equalTo: "Please enter the same value again.",
                    // accept: "Please enter a value with a valid extension.",
                    // maxlength: $.validator.format("Please enter no more than {0} characters."),
                    // minlength: $.validator.format("Please enter at least {0} characters."),
                    // rangelength: $.validator.format("Please enter a value between {0} and {1} characters long."),
                    // range: $.validator.format("Please enter a value between {0} and {1}."),
                    // max: $.validator.format("Please enter a value less than or equal to {0}."),
                    // min: $.validator.format("Please enter a value greater than or equal to {0}.")
                });

                // Formulario de búsqueda
                $('.search-form').each(
                    function () {
                        $(this).validate({
                            // No ignorar inputs ocultos para dropdown.js
                            ignore: '',

                            // Reglas
                            rules: {
                                s: {
                                    required: true
                                }
                            },

                            // Mensajes
                            messages: {
                                s: {
                                    required: "Campo requerido"
                                }
                            },

                            // Estilos
                            errorElement: 'p',
                            errorClass: 'help-block hidden',
                            highlight: function (element, errorClass, validClass) {
                                $(element).closest('.form-group').removeClass('has-success');
                                $(element).closest('.form-group').addClass('has-error');
                            },
                            unhighlight: function (element, errorClass, validClass) {
                                $(element).closest('.form-group').removeClass('has-error');
                                $(element).closest('.form-group').addClass('has-success');
                            }

                        });
                    }
                );

                // Formulario de registro
                $('form[name="registerform"]').each(
                    function () {
                        $(this).validate({
                            // No ignorar inputs ocultos para dropdown.js
                            ignore: '',

                            // Reglas
                            rules: {
                                user_email: {
                                    required: true,
                                    email: true
                                },
                                acceptance: {
                                    required: true
                                }
                            },

                            // Mensajes
                            messages: {
                                user_email: {
                                    required: "Necesitamos tu email para poder registrarte.",
                                    email: "Formato incorrecto."
                                },
                                acceptance: {
                                    required: "Este campo es obligatorio."
                                }
                            },

                            // Estilos
                            errorElement: 'p',
                            errorClass: 'help-block',
                            highlight: function (element, errorClass, validClass) {
                                $(element).closest('.form-group').removeClass('has-success');
                                $(element).closest('.form-group').addClass('has-error');
                            },
                            unhighlight: function (element, errorClass, validClass) {
                                $(element).closest('.form-group').removeClass('has-error');
                                $(element).closest('.form-group').addClass('has-success');
                            }

                        });
                    }
                );

                // Formulario de registro en un paso
                $('form[name="registerform-onestep"]').each(
                    function () {
                        $(this).validate({
                            // No ignorar inputs ocultos para dropdown.js
                            ignore: '',

                            // Reglas
                            rules: {
                                user_email: {
                                    required: true,
                                    email: true
                                },
                                acceptance: {
                                    required: true
                                }
                            },

                            // Mensajes
                            messages: {
                                user_email: {
                                    required: "Necesitamos tu email para poder registrarte.",
                                    email: "Formato incorrecto."
                                },
                                acceptance: {
                                    required: "Este campo es obligatorio."
                                }
                            },

                            // Estilos
                            errorElement: 'p',
                            errorClass: 'help-block',
                            highlight: function (element, errorClass, validClass) {
                                $(element).closest('.form-group').removeClass('has-success');
                                $(element).closest('.form-group').addClass('has-error');
                            },
                            unhighlight: function (element, errorClass, validClass) {
                                $(element).closest('.form-group').removeClass('has-error');
                                $(element).closest('.form-group').addClass('has-success');
                            }

                        });
                    }
                );

                // Formulario de registro en un paso
                $('form[name="age-gate"]').each(
                    function () {
                        $(this).validate({
                            // No ignorar inputs ocultos para dropdown.js
                            ignore: '',

                            // Estilos
                            errorElement: 'p',
                            errorClass: 'help-block',
                            highlight: function (element, errorClass, validClass) {
                                $(element).closest('.form-group').removeClass('has-success');
                                $(element).closest('.form-group').addClass('has-error');
                            },
                            unhighlight: function (element, errorClass, validClass) {
                                $(element).closest('.form-group').removeClass('has-error');
                                $(element).closest('.form-group').addClass('has-success');
                            }
                        });
                    }
                );

                // Formulario de login
                $('form[name="loginform"]').each(
                    function () {
                        $(this).validate({
                            // No ignorar inputs ocultos para dropdown.js
                            ignore: '',

                            // Reglas
                            rules: {
                                log: {
                                    required: true,
                                    email: true
                                },
                                pwd: {
                                    required: true
                                }
                            },

                            // Mensajes
                            messages: {
                                log: {
                                    required: "Escribe tu email para poder acceder.",
                                    email: "Formato incorrecto."
                                },
                                pwd: {
                                    required: "Debes escribir tu contraseña."
                                }
                            },

                            // Estilos
                            errorElement: 'p',
                            errorClass: 'help-block',
                            highlight: function (element, errorClass, validClass) {
                                $(element).closest('.form-group').removeClass('has-success');
                                $(element).closest('.form-group').addClass('has-error');
                            },
                            unhighlight: function (element, errorClass, validClass) {
                                $(element).closest('.form-group').removeClass('has-error');
                                $(element).closest('.form-group').addClass('has-success');
                            }

                        });
                    }
                );

                // Sharrre - https://github.com/Julienh/Sharrre
                // For header RRSS
                // $('.social-share-buttons-main').sharrre({
                //   share: {
                //     facebook: true,
                //     twitter: false
                //   },
                //   template: '<a data-toggle="tooltip" data-placement="bottom" data-container="body" href="#" title="Compartir en Facebook" class="btn btn-xs btn-facebook withripple"><i class="fa fa-fw fa-lg fa-facebook"></i><div class="ripple-container"></div></a><a data-toggle="tooltip" data-placement="bottom" data-container="body" href="#" title="Compartir en Twitter" class="btn btn-xs btn-twitter withripple"><i class="fa fa-fw fa-lg fa-twitter"></i><div class="ripple-container"></div></a>',
                //   enableHover: false,
                //   enableTracking: false,
                //   render: function(api, options) {
                //     $(api.element).on('click', '.btn-facebook', function() {
                //       api.openPopup('facebook');
                //     });
                //     $(api.element).on('click', '.btn-twitter', function() {
                //       api.openPopup('twitter');
                //     });

                //     var $buttons = $('.social-share-buttons [data-toggle="tooltip"]');
                //     $buttons.tooltip();
                //     $.material.ripples($buttons);
                //   }
                // });
                // Notices RRSS
                $('.social-share-buttons').sharrre({
                    share: {
                        facebook: true,
                        twitter: true,
                        linkedin: true,
                        whatsapp: true,
                    },
                    template: '<a data-toggle="tooltip" data-placement="bottom" data-container="body" href="#" title="Compartir en Facebook" class="btn btn-xs btn-facebook withripple"><i class="fa fa-fw fa-lg fa-facebook"></i><div class="ripple-container"></div></a><a data-toggle="tooltip" data-placement="bottom" data-container="body" href="#" title="Compartir en Twitter" class="btn btn-xs btn-twitter withripple"><i class="fa fa-fw fa-lg fa-twitter"></i><div class="ripple-container"></div></a><a data-toggle="tooltip" data-placement="bottom" data-container="body" href="#" title="Compartir en Linkedin" class="btn btn-xs btn-linkedin withripple"><i class="fa fa-fw fa-lg fa-linkedin"></i><div class="ripple-container"></div></a><a data-toggle="tooltip" data-placement="bottom" data-container="body" href="#" title="Compartir en Linkedin" class="btn btn-xs btn-whatsapp withripple"><i class="fa fa-fw fa-lg fa-whatsapp"></i><div class="ripple-container"></div></a>',
                    enableHover: false,
                    enableTracking: false,
                    render: function (api, options) {
                        $(api.element).on('click', '.btn-facebook', function () {
                            var bodyPostIdClass = document.body.className.match(/(^|\s)postid-(\d+)(\s|$)/);
                            var postId = bodyPostIdClass[2];
                            jQuery.ajax({
                                method: 'get',
                                url: window.location.origin + '/wp-json/dnoise/v1/facebook/search/new',
                                data: {
                                    post_id: postId,
                                }
                            });
                            api.openPopup('facebook');
                        });
                        $(api.element).on('click', '.btn-twitter', function () {
                            var bodyPostIdClass = document.body.className.match(/(^|\s)postid-(\d+)(\s|$)/);
                            var postId = bodyPostIdClass[2];
                            jQuery.ajax({
                                method: 'get',
                                url: window.location.origin + '/wp-json/dnoise/v1/twitter/search/new',
                                data: {
                                    post_id: postId,
                                }
                            });
                            api.openPopup('twitter');
                        });
                        $(api.element).on('click', '.btn-linkedin', function () {
                            var bodyPostIdClass = document.body.className.match(/(^|\s)postid-(\d+)(\s|$)/);
                            var postId = bodyPostIdClass[2];
                            jQuery.ajax({
                                method: 'get',
                                url: window.location.origin + '/wp-json/dnoise/v1/linkedin/search/new',
                                data: {
                                    post_id: postId,
                                }
                            });
                            api.openPopup('linkedin');
                        });

                        $(api.element).on('click', '.btn-whatsapp', function () {
                            window.open('whatsapp://send?text=' + window.location.href, '_blank');
                        });


                        var bodyPostIdClass = document.body.className.match(/(^|\s)postid-(\d+)(\s|$)/);
                        var postId = bodyPostIdClass[2];

                        jQuery.ajax({
                            method: 'get',
                            url: window.location.origin + '/wp-json/dnoise/v1/twitter/search',
                            data: {
                                q: window.location.href,
                                post_id: postId,
                            },
                            success: function (response) {

                                var viewsCount = jQuery('.entry-social .post-views .views-counter').html();
                                var count = 0;

                                if (typeof (response.default) !== "undefined") {
                                    count = response.default;

                                    if ((typeof (response.statuses) !== "undefined") && (response.statuses.length !== 0)) {
                                        if (response.statuses.length > 10) {
                                            count = response.statuses.length;
                                        }
                                    }

                                    if (parseInt(count) > parseInt(viewsCount)) {
                                        count = viewsCount - 5;
                                        if (count === 0) {
                                            count = 1;
                                        }
                                    }
                                }
                                else {
                                    count = viewsCount - 5;
                                    if (count === 0) {
                                        count = 1;
                                    }
                                }

                                $('.entry-social .btn-twitter').append('<span class="views-counter">' + count + '</span>');
                            },
                            error: function (response) {
                                console.log('ERROR', response);
                            }
                        });

                        jQuery.ajax({
                            method: 'get',
                            url: window.location.origin + '/wp-json/dnoise/v1/facebook/search',
                            data: {
                                id: window.location.href,
                                post_id: postId,
                            },
                            success: function (response) {

                                var viewsCount = jQuery('.entry-social .post-views .views-counter').html();
                                var count = 10;

                                if (typeof (response.default) !== "undefined") {
                                    count = response.default;

                                    if ((typeof (response.share) !== "undefined") && (typeof (response.share.share_count) !== "undefined")) {
                                        if (response.share.share_count > 10) {
                                            count = response.share.share_count;
                                        }
                                    }

                                    if (parseInt(count) > parseInt(viewsCount)) {
                                        count = viewsCount - 5;
                                        if (count === 0) {
                                            count = 1;
                                        }
                                    }
                                }
                                else {
                                    count = viewsCount;
                                    if (count === 0) {
                                        count = 1;
                                    }
                                }

                                $('.entry-social .btn-facebook').append('<span class="views-counter">' + count + '</span>');
                            },
                            error: function (response) {
                                console.log('ERROR', response);
                            }
                        });

                        var $buttons = $('.social-share-buttons [data-toggle="tooltip"]');
                        $buttons.tooltip();
                        $.material.ripples($buttons);
                    }
                });

            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },

        // Page: /home/
        // -----------------------------------------------------------------------
        'home': {
            init: function () {
                // JavaScript to be fired on the home page

                // Modal registro para usuarios no logeados
                if (!$('body').hasClass('logged-in')) {
                    ouibounce(false, {
                        callback: function () {
                            $('#modal-register-bounce').modal('show');
                        }
                    });
                }

            },

            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        }, // Page: /home/

        // Page: /editar-perfil/
        // -----------------------------------------------------------------------
        'editar_perfil': {
            init: function () {

                function showValidationError() {
                    // console.log("showValidationError");
                    var html_error = '<p id="pass1-error" class="help-block dont-match" style="display: block;">Mínimo 6 caracteres, mayúsculas, minúsculas y números</p>';
                    $('.pass1').parent().find('#pass1-error').remove();
                    $('.pass1').parent().removeClass('has-success').addClass('has-error').append(html_error);
                    $('#submit-profileform').prop("disabled", true);
                }

                function showNotEqualError() {
                    // console.log("showNotEqualError");
                    var html_error = '<p id="pass2-error" class="help-block dont-match" style="display: block;">Las contraseñas no coinciden</p>';
                    $('.pass2').parent().find('#pass2-error').remove();
                    $('.pass2').parent().removeClass('has-success').addClass('has-error').append(html_error);
                    $('#submit-profileform').prop("disabled", true);
                }

                function showValidationCcError() {
                    // console.log("showValidationCcError");
                    $('#submit-password, #submit-profileform').prop('disabled', 'disabled');
                    $('#pass1').parent().removeClass('has-error').find('.error').remove();
                    $('#pass1').parent().addClass('has-error').append('<div class="error">Mínimo 6 caracteres, mayúsculas, minúsculas y números</div>');
                    $('#submit-profileform').prop("disabled", true);
                }

                function showNotEqualCcError() {
                    // console.log("showNotEqualCcError");
                    $('#submit-password, #submit-profileform').prop('disabled', 'disabled');
                    $('#password2').parent().find('.error').remove();
                    $('#password2').parent().addClass('has-error').append('<div class="error">Las contraseñas no coinciden</div>');
                    $('#submit-profileform').prop("disabled", true);
                }

                function showNoCamareroError() {
                    // console.log("showNoCamareroError");
                    swal({
                        title: '¡Lo sentimos!',
                        text: 'Pero debes ser camarero para participar en la presente promoción. Más información en las <a href="/app/uploads/bartalent_lab_concurso_bartalent_2017_v2.pdf" title="Leer: Bases Legales del concurso Bartalent 2017" target="_blank" class="text-700 text-underline">Bases Legales</a>.',
                        html: true,
                        type: 'error',
                        confirmButtonText: 'Aceptar'
                    });
                    $('#submit-profileform').prop("disabled", true);
                }

                function showProfVaciaError() {
                    // console.log("showProfVaciaError");
                    $('#submit-profileform').prop("disabled", true);
                }

                function showEdadVaciaError() {
                    // console.log("showEdadVaciaError");
                    $('#submit-profileform').prop("disabled", true);
                }
                function showProvinciaVaciaError() {
                    // console.log("showProvinciaVaciaError");
                    $('#submit-profileform').prop("disabled", true);
                }
                function showProfesionVaciaError() {
                    // console.log("showProfesionVaciaError");
                    $('#submit-profileform').prop("disabled", true);
                }
                function showNombreEstabVaciaError() {
                    // console.log("showNombreEstabVaciaError");
                    $('#submit-profileform').prop("disabled", true);
                }
                function showAliasVaciaError() {
                    // console.log("showAliasVaciaError");
                    $('#submit-profileform').prop("disabled", true);
                }

                function clearFormErrors() {
                    $('.pass1').parent().addClass('has-success').removeClass('has-error').find('.dont-match').remove();
                    $('.pass2').parent().addClass('has-success').removeClass('has-error').find('.dont-match').remove();
                    $('#pass1').parent().removeClass('has-error').find('.error').remove();
                    $('#password2').parent().removeClass('has-error').find('.error').remove();
                }

                function isObligatoryProfileForm() {
                    return ($("#typeprofileform").val() !== "complete");
                }

                function markCorrectFields() {
                    var selectprof = $('select[name=profesion]').val();
                    var selectedad = $('select[name=edad]').val();
                    var selectprovincia = $('select[name=provincia]').val();
                    var selecttipoest = $('select[name=tipo_establecimiento]').val();
                    // var nombreest = $('input[name=establecimiento]').val();
                    var alias = $('input[name=display_name]').val();
                    var dni = $('input[name=dni]').val();

                    if ((selectprof !== 'no-camarero') && (selectprof !== '')) {
                        $('select[name=profesion]').closest('.form-group').removeClass('has-error').addClass('has-success');
                    }
                    else {
                        $('select[name=profesion]').closest('.form-group').removeClass('has-error').removeClass('has-success');
                    }

                    if (selectedad !== '') {
                        $('select[name=edad]').closest('.form-group').removeClass('has-error').addClass('has-success');
                    }
                    else {
                        $('select[name=edad]').closest('.form-group').removeClass('has-error').removeClass('has-success');
                    }

                    if (selectprovincia !== '') {
                        $('select[name=provincia]').closest('.form-group').removeClass('has-error').addClass('has-success');
                    }
                    else {
                        $('select[name=provincia]').closest('.form-group').removeClass('has-error').removeClass('has-success');
                    }

                    if (selecttipoest !== '') {
                        $('select[name=tipo_establecimiento]').closest('.form-group').removeClass('has-error').addClass('has-success');
                    }
                    else {
                        $('select[name=tipo_establecimiento]').closest('.form-group').removeClass('has-error').removeClass('has-success');
                    }

                    // if (nombreest !== '') 
                    // {
                    //   $('input[name=establecimiento]').closest('.form-group').removeClass('has-error').addClass('has-success');
                    // }
                    // else
                    // {
                    //   $('select[name=establecimiento]').closest('.form-group').removeClass('has-error').removeClass('has-success');
                    // }
                }

                function formCorrectValidated() {
                    clearFormErrors();
                    $('#submit-profileform').prop("disabled", false);

                    if (!isObligatoryProfileForm()) {
                        $('#submit-password, #submit-profileform').prop('disabled', false);
                    }
                }

                function validatePerfilForm() {
                    // console.log("validatePerfilForm", isObligatoryProfileForm());
                    var pass1 = $('.pass1').val();
                    var pass2 = $('.pass2').val();
                    var pass1cc = $('#pass1').val();
                    var pass2cc = $('#password2').val();

                    var selectprof = $('select[name=profesion]').val();
                    var selectedad = $('select[name=edad]').val();
                    var selectprovincia = $('select[name=provincia]').val();
                    var selecttipoest = $('select[name=tipo_establecimiento]').val();
                    // var nombreest = $('input[name=establecimiento]').val();
                    var alias = $('input[name=display_name]').val();
                    var dni = $('input[name=dni]').val();
                    var pattern = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$");
                    var errors = [];

                    if ((isObligatoryProfileForm()) && (!pattern.test(pass1))) { errors.push('regexpass'); }
                    if ((!isObligatoryProfileForm()) && (pass1cc !== '') && (pass1cc !== 'undefined') && (!pattern.test(pass1cc))) { errors.push('regexpasscc'); }

                    if ((isObligatoryProfileForm()) && ((pass1 !== 'undefined') && (pass1 !== pass2))) { errors.push('notequalpass'); }
                    if ((!isObligatoryProfileForm()) && (pass2cc !== '') && (pass2cc !== 'undefined') && (pass1cc !== pass2cc)) { errors.push('notequalpasscc'); }

                    if (selectprof === 'no-camarero') { errors.push('profnocamarero'); }
                    if (selectprof === '') { errors.push('profvacia'); }
                    if (selectedad === '') { errors.push('edadvacia'); }
                    if (selectprovincia === '') { errors.push('provinciavacia'); }
                    if (selecttipoest === '') { errors.push('profvacia'); }
                    // if (nombreest === '') { errors.push('nombreestvacia'); }
                    if (alias === '') { errors.push('aliasvacia'); }

                    clearFormErrors();

                    markCorrectFields();

                    if (errors.length) {
                        var act_err = null;
                        for (var i = errors.length - 1; i >= 0; i--) {
                            act_err = errors.pop();

                            if (act_err === 'regexpass') { showValidationError(); }
                            if (act_err === 'regexpasscc') { showValidationCcError(); }
                            if (act_err === 'notequalpass') { showNotEqualError(); }
                            if (act_err === 'notequalpasscc') { showNotEqualCcError(); }
                            if (act_err === 'profnocamarero') { showNoCamareroError(); }
                            if (act_err === 'profvacia') { showProfVaciaError(); }
                            if (act_err === 'edadvacia') { showEdadVaciaError(); }
                            if (act_err === 'provinciavacia') { showProvinciaVaciaError(); }
                            if (act_err === 'profvacia') { showProfesionVaciaError(); }
                            // if (act_err === 'nombreestvacia') { showNombreEstabVaciaError(); }
                            if (act_err === 'aliasvacia') { showAliasVaciaError(); }
                        }
                        return;
                    }
                    else {
                        formCorrectValidated();
                    }
                }

                // ScrollReveal
                // https://github.com/jlmakes/scrollreveal.js
                (function () {

                    window.sr = new ScrollReveal();

                    sr.reveal('.wizard .reveal', {
                        duration: 1000,
                        origin: 'left',
                        distance: '30px',
                        scale: 0.5,
                        mobile: false,
                        // reset: false
                    }, 100);

                })(); // ScrollReveal


                // Vars
                var $profileForm = $('form[name="profileform"]');
                var $instanceId = $profileForm.data('instance');
                var $profesionSelect = $profileForm.find('#profesion' + '-' + $instanceId);
                var $legends = $profileForm.find('legend').not('.keep');
                var $passwordGenerator = $profileForm.find('#password-generator');
                var $passwordFields = $passwordGenerator.find('.form-group');
                var $requiredGroups = $profileForm.find('[required]').closest('.form-group');
                var $notRequiredGroups = $profileForm.find('.form-group').not($requiredGroups).add($legends);
                var $submitButton = $profileForm.find('#submit-profileform');

                if ($profileForm.not('.form-complete').hasClass('required-fields-incomplete')) {
                    $('#alert-required').slideDown();
                    $notRequiredGroups.hide();
                } else if ($profileForm.not('.form-complete').hasClass('required-fields-complete')) {
                    $('#alert-required').slideUp();
                    $('#alert-points').slideDown();
                    $('.wizard .step-2 .status .fa-times-circle').hide();
                    $('.wizard .step-2 .status .fa-check-circle').show();
                } else if ($profileForm.hasClass('form-complete')) {
                    $('#alert-required').hide();
                    $('#alert-points').hide();
                    $('.wizard .step-3 .status .fa-times-circle').hide();
                    $('.wizard .step-3 .status .fa-check-circle').show();
                }

                var all_dnis = [];
                var all_alias = [];

                function getAllDNIs() {
                    jQuery.ajax({
                        method: 'get',
                        url: window.location.origin + '/wp-json/dnoise/v1/getalldnis',
                        success: function (response) {

                            all_dnis = response;

                        }
                    });
                }

                function getAllAlias() {
                    jQuery.ajax({
                        method: 'get',
                        url: window.location.origin + '/wp-json/dnoise/v1/getallalias',
                        success: function (response) {

                            all_alias = response;
                        }
                    });
                }

                getAllDNIs();
                getAllAlias();

                $('select[name=provincia]').on('change', function () {
                    validatePerfilForm();
                });

                $('select[name=edad]').on('change', function () {
                    validatePerfilForm();
                });

                $('select[name=tipo_establecimiento]').on('change', function () {
                    validatePerfilForm();
                });

                $('input[name=dni]').on('change', function () {
                    validatePerfilForm();
                });

                $('input[name=display_name]').on('change', function () {
                    validatePerfilForm();
                });

                $(document).on('keyup', '.pass1', function (e) {
                    validatePerfilForm();
                });

                $(document).on('keyup', '.pass2', function (e) {
                    validatePerfilForm();
                });

                $(document).on('blur', '.pass1', function (e) {
                    validatePerfilForm();
                });

                $(document).on('blur', '.pass2', function (e) {
                    validatePerfilForm();
                });

                $(document).on('keyup', '#password-generator #pass1, #password-generator #pass1-text', function (e) {
                    validatePerfilForm();
                });

                $(document).on('keyup', '#password-generator #password2', function (e) {
                    validatePerfilForm();
                });

                $(document).on('blur', '#password-generator #pass1, #password-generator #pass1-text', function (e) {
                    validatePerfilForm();
                });

                $(document).on('blur', '#password-generator #password2', function (e) {
                    validatePerfilForm();
                });


                // Tooltips en dropdowns obligatos
                $profileForm.find('select[required]').next('.dropdownjs').find('.fakeinput').attr({
                    'title': 'Obligatorio',
                    'data-toggle': 'tooltip',
                    'data-placement': 'bottom',
                    'data-container': 'body'
                }).tooltip();

                function validateDNI(dni) {
                    var number, le, letr;
                    var er = /^[XYZ]?\d{5,8}[A-Z]$/;

                    dni = dni.toUpperCase();

                    if (er.test(dni) === true) {
                        number = dni.substr(0, dni.length - 1);
                        number = number.replace('X', 0);
                        number = number.replace('Y', 1);
                        number = number.replace('Z', 2);
                        le = dni.substr(dni.length - 1, 1);
                        number = number % 23;
                        letr = 'TRWAGMYFPDXBNJZSQVHLCKET';
                        letr = letr.substring(number, number + 1);
                        if (letr !== le) {
                            return false;
                        }
                        else {
                            return true;
                        }
                    }
                    else {
                        return false;
                    }
                }

                function validateAlias(alias) {
                    var regExNick = /^[A-Za-z0-9_-ñÑ]{3,26}$/;
                    if (!regExNick.test(alias)) {
                        return false;
                    }
                    return true;
                }

                function validateDNISubmit(e) {
                    var dni = $('input[name=dni]').val();
                    var uid = $('input[name=checkuser_id]').val();
                    if (!validateDNI(dni)) {
                        swal({
                            title: '¡Lo sentimos!',
                            text: 'Formato de DNI incorrecto',
                            html: true,
                            type: 'error',
                            confirmButtonText: 'Aceptar'
                        });
                        $('#submit-profileform').prop("disabled", true);
                        $('input[name=dni]').closest('.form-group').removeClass('has-success').addClass('has-error');
                        e.preventDefault();
                    }
                    else {
                        if (all_dnis.indexOf(dni + "-" + uid) !== -1) // en el mismo usuario, actualizacion normal, nada que hacer
                        {

                        }
                        else {
                            for (var i = 0; i < all_dnis.length; i += 1) {
                                var res = all_dnis[i].split("-");
                                if ((res[0] === dni) && (res[1] !== uid)) {
                                    swal({
                                        title: '¡Lo sentimos!',
                                        text: 'El DNI pertenece a otro usuario',
                                        html: true,
                                        type: 'error',
                                        confirmButtonText: 'Aceptar'
                                    });
                                    $('#submit-profileform').prop("disabled", true);
                                    $('input[name=dni]').closest('.form-group').removeClass('has-success').addClass('has-error');
                                    e.preventDefault();
                                }
                            }
                        }
                    }
                }

                function validateAliasSubmit(e) {
                    var display_name = $('input[name=display_name]').val();
                    var uid = $('input[name=checkuser_id]').val();
                    if (!validateAlias(display_name)) {
                        swal({
                            title: '¡Lo sentimos!',
                            text: 'Formato de Alias incorrecto',
                            html: true,
                            type: 'error',
                            confirmButtonText: 'Aceptar'
                        });
                        $('#submit-profileform').prop("disabled", true);
                        $('input[name=display_name]').closest('.form-group').removeClass('has-success').addClass('has-error');
                        e.preventDefault();
                    }
                    else {
                        if (all_alias.indexOf(display_name + "-" + uid) !== -1) // en el mismo usuario, actualizacion normal, nada que hacer
                        {

                        }
                        else {
                            for (var i = 0; i < all_alias.length; i += 1) {
                                var res = all_alias[i].split("-");
                                if ((res[0] === display_name) && (res[1] !== uid)) {
                                    swal({
                                        title: '¡Lo sentimos!',
                                        text: 'El Alias pertenece a otro usuario',
                                        html: true,
                                        type: 'error',
                                        confirmButtonText: 'Aceptar'
                                    });
                                    $('#submit-profileform').prop("disabled", true);
                                    $('input[name=display_name]').closest('.form-group').removeClass('has-success').addClass('has-error');
                                    e.preventDefault();
                                }
                            }
                        }
                    }
                }

                $submitButton.on('click', function (e) {
                    validateDNISubmit(e);
                    validateAliasSubmit(e);
                });

                // Select condicional de profesión
                $profesionSelect.next('.dropdownjs').find('li').on('click', function () {
                    var v = $(this).attr('value');
                    $(this).closest('.form-group').find('.select').val(v).trigger('change');
                });

                $profesionSelect.on('change', function () {
                    var select = $(this).val();
                    var target = $('label[for="establecimiento' + '-' + $instanceId + '"]');
                    var targetInput = target.siblings('input');
                    var targetHelp = target.siblings('.help-block').find('span');
                    var targetParent = target.parent();

                    // function addRequired() {
                    //   if ( targetParent.not(':visible') ) {
                    //     targetParent.slideDown();
                    //   }
                    //   target.children('sup').remove();
                    //   targetInput.prop('required', true).attr({
                    //     'title': 'Obligatorio',
                    //     'data-toggle': 'tooltip',
                    //     'data-placement': 'bottom',
                    //     'data-container': 'body'
                    //   }).tooltip();
                    //   target.addClass('control-label-required').append('<sup class="sup-icon"><i class="fa fa-asterisk"></i></sup>');
                    //   $requiredGroups = $profileForm.find('[required]').closest('.form-group');
                    //   $notRequiredGroups = $profileForm.find('.form-group').not($requiredGroups).add($legends);
                    // }

                    // function removeRequired() {
                    //   targetInput.removeAttr('title data-toggle data-placement data-container required').tooltip('destroy');
                    //   target.removeClass('control-label-required').children('sup').remove();
                    //   $requiredGroups = $profileForm.find('[required]').closest('.form-group');
                    //   $notRequiredGroups = $profileForm.find('.form-group').not($requiredGroups).add($legends);
                    //   if ( targetParent.is(':visible') ) {
                    //     targetParent.slideUp();
                    //   }
                    // }

                    // switch(select) {
                    //   case 'camarero':
                    //     if (typeof versaTag !== 'undefined') {
                    //       versaTag.activityParams.productinfo = 'camarero';
                    //     }
                    //     addRequired();
                    //     targetHelp.text('Establecimiento en el que trabajas.');
                    //     targetHelp.prev('i').show();
                    //     break;

                    //   case 'busca-empleo':
                    //     if (typeof versaTag !== 'undefined') {
                    //     versaTag.activityParams.productinfo = 'busca-empleo';
                    //     }
                    //     addRequired();
                    //     targetHelp.text('Establecimiento en el que trabajaste la última vez.');
                    //     targetHelp.prev('i').show();
                    //     break;

                    //   case 'estudiante':
                    //     if (typeof versaTag !== 'undefined') {
                    //     versaTag.activityParams.productinfo = 'estudiante';
                    //     }
                    //     addRequired();
                    //     targetHelp.text('Centro de formación en el que estás matriculado.');
                    //     targetHelp.prev('i').show();
                    //     break;

                    //   case 'no-camarero':
                    //     if (typeof versaTag !== 'undefined') {
                    //       versaTag.activityParams.productinfo = 'no-camarero';
                    //     }
                    //     removeRequired();
                    //     break;

                    //   default:
                    //     removeRequired();
                    //     break;
                    // }

                    validatePerfilForm();

                });

                if ($profesionSelect.val() === 'camarero' || $profesionSelect.val() === 'busca-empleo' || $profesionSelect.val() === 'estudiante') {
                    $profesionSelect.trigger('change');
                }

                // Validaciones
                // var profileFormValidator = $profileForm.validate({
                //   // No ignorar inputs ocultos para dropdown.js
                //   ignore: '',
                //   // Estilos
                //   errorElement : 'p',
                //   errorClass : 'help-block',
                //   highlight : function(element, errorClass, validClass) {
                //     $(element).closest('.form-group').removeClass('has-success');
                //     $(element).closest('.form-group').addClass('has-error');
                //   },
                //   unhighlight: function(element, errorClass, validClass) {
                //     $(element).closest('.form-group').removeClass('has-error');
                //     $(element).closest('.form-group').addClass('has-success');
                //   }

                // });


                // Alerta de puntos
                $('#btn-fillnow').on('click', function () {
                    $notRequiredGroups.slideDown('slow');
                    $profileForm.find('#first_name' + '-' + $instanceId).focus();
                });

                // Cerrar alerta
                $('#alert-points').on('close.bs.alert', function () {
                    $(this).find('.tooltiped').tooltip('destroy');
                    $submitButton.show();
                    $('html, body').animate({
                        scrollTop: 0
                    }, 600);
                    profileFormValidator.focusInvalid();
                });

                // Guardar contraseña
                $('#submit-password').on('click', function () {
                    $submitButton.click();
                });


            }
        }, // Page: /editar-perfil/

        // Page: /be-a-bartalent/
        // -----------------------------------------------------------------------
        'be_a_bartalent': {
            init: function () {

                // ScrollReveal
                // https://github.com/jlmakes/scrollreveal.js
                (function () {

                    window.sr = new ScrollReveal();

                    sr.reveal('.pasos .reveal', {
                        duration: 1000,
                        origin: 'left',
                        distance: '30px',
                        scale: 0.5,
                        mobile: false,
                        // reset: false
                    }, 100);

                    sr.reveal('.section-premios', {
                        delay: 1000,
                        duration: 1000,
                        origin: 'top',
                        scale: 0.5,
                        viewFactor: 0.01,
                        mobile: false,
                        reset: false
                    });

                    sr.reveal('.section-unete .reveal', {
                        duration: 1000,
                        scale: 0.5,
                        viewFactor: 1,
                        mobile: false,
                        // reset: false
                    });

                })(); // ScrollReveal

                $('.equalheight-heading').matchHeight({
                    byRow: true,
                    property: 'height'
                });

                $('.equalheight-lead').matchHeight({
                    byRow: true,
                    property: 'height'
                });

                $('.beneficio').matchHeight({
                    byRow: false,
                    property: 'height'
                });

            }
        }, // Page: /be-a-bartalent/

        // Page: /tu-espacio/
        // -----------------------------------------------------------------------
        'tu_espacio': {
            init: function () {

                $('#showedVideosListMoreBtn').click(function (event) {
                    event.preventDefault();
                    $videos = $(this).closest('.section-action').prev('#showedVideosList');
                    $videos.toggleClass('limited unlimited');
                    setTimeout(function () {
                        $videos.toggleClass('out in');
                    }, 200);
                    $(this).text(function (i, text) {
                        return text === 'Cargar más' ? 'Mostrar menos' : 'Cargar más';
                    });
                    $('html, body').animate({
                        scrollTop: $(this).closest('.pildoras').offset().top
                    }, 600);
                });

                $('#suggestedVideosListMoreBtn').click(function (event) {
                    event.preventDefault();
                    $videos = $(this).closest('.section-action').prev('#suggestedVideosList');
                    $videos.toggleClass('limited unlimited');
                    setTimeout(function () {
                        $videos.toggleClass('out in');
                    }, 200);
                    $(this).text(function (i, text) {
                        return text === 'Cargar más' ? 'Mostrar menos' : 'Cargar más';
                    });
                    $('html, body').animate({
                        scrollTop: $(this).closest('.pildoras').offset().top
                    }, 600);
                });

                $('#unreadDegustaNewsListMoreBtn').click(function (event) {
                    event.preventDefault();
                    $videos = $(this).closest('.section-action').prev('#unreadDegustanewsList');
                    $videos.toggleClass('limited unlimited');
                    setTimeout(function () {
                        $videos.toggleClass('out in');
                    }, 200);
                    $(this).text(function (i, text) {
                        return text === 'Cargar más' ? 'Mostrar menos' : 'Cargar más';
                    });
                    $('html, body').animate({
                        scrollTop: $(this).closest('.pildoras').offset().top
                    }, 600);
                });

                $('#button-superquizz-banner').click(function (event) {
                    event.preventDefault();
                    $(".superquizz-quizz").slideDown("slow", function () {
                        // Animation complete.
                    });
                });

                $(document).ready(function () {

                    if ($('.superquizz-quizz').length > 0) {
                        $(".superquizz-quizz").hide();
                    }

                    $("input[name='avatar_submit']").val("Guardar Foto");

                    $("input[name='avatar_submit']").hover(function () {
                        $(this).css("text-decoration", "underline");
                    }, function () {
                        $(this).css("text-decoration", "none");
                    });

                });

            }
        }, // Page: /tu-espacio/

        // Page: /6f58a3f58af3b538836ad5dfab865dbfabe/ PROMO LANDING
        // -----------------------------------------------------------------------
        '6f58a3f58af3b538836ad5dfab865dbfabe': {
            init: function () {

                // ScrollReveal https://github.com/jlmakes/scrollreveal.js
                (function () {

                    window.sr = new ScrollReveal();

                    sr.reveal('.wizard .reveal', {
                        duration: 1000,
                        origin: 'left',
                        distance: '30px',
                        scale: 0.5,
                        mobile: false,
                        // reset: false
                    }, 100);

                })(); // ScrollReveal

                var $profileForm = $('form[name="profileform"]');
                var $instanceId = $profileForm.data('instance');
                var $profesionSelect = $profileForm.find('#profesion' + '-' + $instanceId);
                var $legends = $profileForm.find('legend').not('.keep');
                var $passwordGenerator = $profileForm.find('#password-generator');
                var $passwordFields = $passwordGenerator.find('.form-group');
                var $requiredGroups = $profileForm.find('[required]').closest('.form-group');
                var $notRequiredGroups = $profileForm.find('.form-group').not($requiredGroups).add($legends);
                var $submitButton = $profileForm.find('#submit-profileform');


                // Select condicional de profesión
                $profesionSelect.next('.dropdownjs').find('li').on('click', function () {
                    var v = $(this).attr('value');
                    $(this).closest('.form-group').find('.select').val(v).trigger('change');
                });

                $profesionSelect.on('change', function () {
                    var select = $(this).val();
                    var target = $('label[for="establecimiento' + '-' + $instanceId + '"]');
                    var targetInput = target.siblings('input');
                    var targetHelp = target.siblings('.help-block').find('span');
                    var targetParent = target.parent();

                    function addRequired() {
                        if (targetParent.not(':visible')) {
                            targetParent.slideDown();
                        }
                        target.children('sup').remove();
                        targetInput.prop('required', true).attr({
                            'title': 'Obligatorio',
                            'data-toggle': 'tooltip',
                            'data-placement': 'bottom',
                            'data-container': 'body'
                        }).tooltip();
                        target.addClass('control-label-required').append('<sup class="sup-icon"><i class="fa fa-asterisk"></i></sup>');
                        $requiredGroups = $profileForm.find('[required]').closest('.form-group');
                        $notRequiredGroups = $profileForm.find('.form-group').not($requiredGroups).add($legends);
                    }

                    function removeRequired() {
                        targetInput.removeAttr('title data-toggle data-placement data-container required').tooltip('destroy');
                        target.removeClass('control-label-required').children('sup').remove();
                        $requiredGroups = $profileForm.find('[required]').closest('.form-group');
                        $notRequiredGroups = $profileForm.find('.form-group').not($requiredGroups).add($legends);
                        if (targetParent.is(':visible')) {
                            targetParent.slideUp();
                        }
                    }

                    switch (select) {
                        case 'camarero':
                            $submitButton.removeAttr('disabled');
                            if (typeof versaTag !== 'undefined') {
                                versaTag.activityParams.productinfo = 'camarero';
                            }
                            addRequired();
                            break;

                        case 'busca-empleo':
                            $submitButton.removeAttr('disabled');
                            if (typeof versaTag !== 'undefined') {
                                versaTag.activityParams.productinfo = 'busca-empleo';
                            }
                            addRequired();
                            break;

                        case 'estudiante':
                            $submitButton.removeAttr('disabled');
                            if (typeof versaTag !== 'undefined') {
                                versaTag.activityParams.productinfo = 'estudiante';
                            }
                            addRequired();
                            break;

                        case 'no-camarero':
                            $submitButton.attr('disabled', 'disabled');
                            swal({
                                title: '¡Lo sentimos!',
                                text: 'Pero debes ser camarero para participar en la presente promoción. Más información en las <a href="/app/uploads/bartalent_lab_concurso_bartalent_2017_v2.pdf" title="Leer: Bases Legales del concurso Bartalent 2017" target="_blank" class="text-700 text-underline">Bases Legales</a>.',
                                html: true,
                                type: 'error',
                                confirmButtonText: 'Aceptar'
                            });

                            if (typeof versaTag !== 'undefined') {
                                versaTag.activityParams.productinfo = 'no-camarero';
                            }

                            removeRequired();
                            break;

                        default:
                            removeRequired();
                            break;
                    }
                });

                if ($profesionSelect.val() === 'camarero' || $profesionSelect.val() === 'busca-empleo' || $profesionSelect.val() === 'estudiante') {
                    $profesionSelect.trigger('change');
                }

                // Validaciones
                var profileFormValidator = $profileForm.validate({
                    // No ignorar inputs ocultos para dropdown.js
                    ignore: '',

                    // Estilos
                    errorElement: 'p',
                    errorClass: 'help-block',
                    highlight: function (element, errorClass, validClass) {
                        $(element).closest('.form-group').removeClass('has-success');
                        $(element).closest('.form-group').addClass('has-error');
                    },
                    unhighlight: function (element, errorClass, validClass) {
                        $(element).closest('.form-group').removeClass('has-error');
                        $(element).closest('.form-group').addClass('has-success');
                    }

                });

            }
        }, // Page: /5F2BA3F58AFAAC8854AD5DFA9905DBFAFF/
        '5f2ba3f58afaac8854ad5dfa9905dbfaff': {
            init: function () {

                $(document).ready(function () {

                    var user_id_registered = $(".user-registered-check").val();
                    var error = false;
                    var text_error = "";

                    if (user_id_registered === "-4") {
                        error = true;
                        text_error = "Pero el dni ya se encuentra registrado en otro usuario de Bartalent Lab";
                    }
                    if (user_id_registered === "-3") {
                        error = true;
                        text_error = "Pero tu email ya se encuentra registrado en Bartalent Lab";
                    }
                    if (user_id_registered === "-1") {
                        error = true;
                        text_error = "Pero no se ha podido identificar el usuario";
                    }
                    if (user_id_registered === "0") {
                        error = true;
                        text_error = "Pero no hemos podido completar tu registro debido a un error interno. Por favor intenta más tarde.";
                    }

                    if (error) {
                        swal({
                            title: '¡Lo sentimos!',
                            text: text_error,
                            html: true,
                            type: 'error',
                            confirmButtonText: 'Aceptar'
                        },
                            function () {
                                location.href = "../6f58a3f58af3b538836ad5dfab865dbfabe";
                            });
                    }

                });

            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    $('.slick-slide-content').on('click', function () {
        var link = $(".slick-current a#button-main-slider").prop("href");
        // console.log(link);
        location.href = link;
    });

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

jQuery(document).on('submit', '.wpua_image_form', function () {
    setTimeout(function () {
        jQuery('input[name="avatar_submit"]').trigger('click');
    }, 1600);
});

jQuery.extend(jQuery.validator.messages, {
    required: "Este campo es obligatorio.",
    remote: "Por favor, rellena este campo.",
    email: "Por favor, escribe una dirección de correo válida",
    url: "Por favor, escribe una URL válida.",
    date: "Por favor, escribe una fecha válida.",
    dateISO: "Por favor, escribe una fecha (ISO) válida.",
    number: "Por favor, escribe solo números.",
    digits: "Por favor, escribe sólo dígitos.",
    creditcard: "Por favor, escribe un número de tarjeta válido.",
    equalTo: "Por favor, escribe el mismo valor de nuevo.",
    accept: "Por favor, escribe un valor con una extensión aceptada.",
    maxlength: jQuery.validator.format("Por favor, no escribas más de {0} caracteres."),
    minlength: jQuery.validator.format("Por favor, no escribas menos de {0} caracteres."),
    rangelength: jQuery.validator.format("Por favor, escribe un valor entre {0} y {1} caracteres."),
    range: jQuery.validator.format("Por favor, escribe un valor entre {0} y {1}."),
    max: jQuery.validator.format("Por favor, escribe un valor menor o igual a {0}."),
    min: jQuery.validator.format("Por favor, escribe un valor mayor o igual a {0}.")
});

jQuery(document).on('click', '.wp-generate-pw', function () {
    jQuery('.wp-hide-pw').trigger('click');
});

function checkScreenSize() {
    var newWindowWidth = jQuery(window).width();
    if (newWindowWidth < 481) {
        jQuery('.tab-pane').hide();
        jQuery('#_all').show();
        jQuery('#select-tab').change(function () {
            dropdown = jQuery('#select-tab').val();
            jQuery('.tab-pane').hide();
            jQuery('#' + "_" + dropdown).show();
        });
    }
}

jQuery(document).ready(function () {
    if (jQuery('.mixernoticias_loadmore')) {
        jQuery('.mixernoticias_loadmore').click(function (event) {
            event.preventDefault();
            var button = jQuery(this),
                data = {
                    'action': 'loadmore',
                    'query': mixernoticias_loadmore_params.posts, // that's how we get params from wp_localize_script() function
                    'page': mixernoticias_loadmore_params.current_page
                };

            jQuery.ajax({ // you can also use $.post here
                url: mixernoticias_loadmore_params.ajaxurl, // AJAX handler
                data: data,
                type: 'POST',
                beforeSend: function (xhr) {
                    button.text('Cargando...'); // change the button text, you can also add a preloader image
                },
                success: function (data) {
                    if (data) {
                        button.text('Ver más'); // insert new posts
                        jQuery('.loadmore_data').append(data);
                        mixernoticias_loadmore_params.current_page++;
                        jQuery('.equalheight-row').matchHeight({});
                        if (mixernoticias_loadmore_params.current_page === mixernoticias_loadmore_params.max_page) {
                            button.remove();
                        }
                    } else {
                        button.remove();
                    }
                }
            });
        });
    }

    if (jQuery('.mixervideos_loadmore')) {
        jQuery('.mixervideos_loadmore').click(function (event) {
            event.preventDefault();
            var button = jQuery(this),
                data = {
                    'action': 'loadmore_videos',
                    'query': mixervideos_loadmore_params.posts, // that's how we get params from wp_localize_script() function
                    'page': mixervideos_loadmore_params.current_page
                };

            jQuery.ajax({ // you can also use $.post here
                url: mixervideos_loadmore_params.ajaxurl, // AJAX handler
                data: data,
                type: 'POST',
                beforeSend: function (xhr) {
                    button.text('Cargando...'); // change the button text, you can also add a preloader image
                },
                success: function (data) {
                    if (data) {
                        button.text('ver todos los vídeos'); // insert new posts
                        jQuery('.loadmore_data').append(data);
                        mixervideos_loadmore_params.current_page++;
                        jQuery('.equalheight-row').matchHeight({});
                        if (mixervideos_loadmore_params.current_page === mixervideos_loadmore_params.max_page) {
                            button.remove();
                        }
                    } else {
                        button.remove();
                    }
                }
            });
        });
    }

    jQuery('.descargaregistro').click(function (event) {
        if (!jQuery('body').hasClass('logged-in')) {
            event.preventDefault();
            window.location = window.location.origin + '/registro';
        }
    });
});


