jQuery(document).ready(function(){
    if (typeof ga === 'function') {

        jQuery(document).on('submit', '#registerform-2', function() {
            if(typeof sentGAEvent === "undefined") {
                ga('send', 'event', 'Engagement', 'Registro');
                console.log('Event Sent: ' + 'Registro');
                sentGAEvent = true;
            }
        });

        jQuery(document).on('submit', '#registerform-3', function() {
            if(typeof sentGAEvent === "undefined") {
                ga('send', 'event', 'Engagement', 'Registro Modal');
                console.log('Event Sent: ' + 'Registro Modal');
                sentGAEvent = true;
            }
        });

        jQuery(document).on('submit', '#profileform-0', function() {
            if(typeof sentGAEvent === "undefined") {
                if (jQuery(this).hasClass('required-fields-incomplete')) {
                    ga('send', 'event', 'Engagement', 'Registro Participante');
                    console.log('Event Sent: ' + 'Registro Participante');
                } else if (jQuery(this).hasClass('form-complete')) {
                    ga('send', 'event', 'Engagement', 'Editar Perfil');
                    console.log('Event Sent: ' + 'Editar Perfil');
                } else {

                    var empty = jQuery(this).find("input").filter(function () {
                        return this.value === "";
                    });
                    if (empty.length) {
                        //At least one input is empty
                        ga('send', 'event', 'Engagement', 'Editar Perfil');
                        console.log('Event Sent: ' + 'Editar Perfil');
                    } else {

                    }
                }
                sentGAEvent = true;
            }
        });

        jQuery(document).on('click', 'a[data-target="#modal-aviso-legal"]', function() {
            ga('send', 'event', 'Enlaces', 'Aviso Legal');
            console.log('Event Sent: ' + 'Aviso Legal');
        });

        jQuery(document).on('click', 'a[data-target="#modal-politica-de-privacidad"]', function() {
            ga('send', 'event', 'Enlaces', 'Politica de Privacidad');
            console.log('Event Sent: ' + 'Politica de Privacidad');
        });

        jQuery(document).on('click', 'a[data-target="#modal-politica-de-cookies"]', function() {
            ga('send', 'event', 'Enlaces', 'Politica de Cookies');
            console.log('Event Sent: ' + 'Politica de Cookies');
        });

        jQuery(document).on('click', 'ul[aria-labelledby="bases-legales"] a', function() {
            var title = jQuery(this).attr('title');
            ga('send', 'event', 'Descargas', title);
            console.log('Event Sent: ' + title);
        });

        jQuery(document).on('click', '.ccip_pildora a[href$=".pdf"]', function() {
            var title = 'Descarga PDF: ' + jQuery.trim(jQuery('.entry-title').text());
            ga('send', 'event', 'Descargas', title);
            console.log('Event Sent: ' + title);
        });

        jQuery(document).on('click', '.ccip_mix_video a[href$=".pdf"]', function() {
            var title = 'Descarga PDF: ' + jQuery.trim(jQuery('.entry-title').text());
            ga('send', 'event', 'Descargas', title);
            console.log('Event Sent: ' + title);
        });

        jQuery('#banner_registro_sidebar').click(function() {
            ga('send', 'event', 'banner registro sidebar', 'clic', 'blog', 0);
        });

        jQuery(document).on('click', '.banco-de-imagenes .banner-principal a', function() {
            ga('send', 'event', 'botón banco de imagenes', 'clic', 'cabecera', 0);
        });

        jQuery(document).on('click', '#banco-entra-ahora-comida a', function() {
            ga('send', 'event', 'botón banco de imagenes', 'clic', 'comida bebida', 0);
        });

        jQuery(document).on('click', '#banco-entra-ahora-ofertas a', function() {
            ga('send', 'event', 'botón banco de imagenes', 'clic', 'oferta servicios', 0);
        });

        jQuery(document).on('click', '#banco-imagen-portatil', function() {
            ga('send', 'event', 'botón banco de imagenes', 'clic', 'portatil', 0);
        });

        jQuery(document).on('click', '#banco-imagen-portatil-responsive', function() {
            ga('send', 'event', 'botón banco de imagenes', 'clic', 'portatil', 0);
        });

        jQuery(document).on('click', '#delivery-autologin', function() {
            ga('send', 'event', 'botón ir a delivery', 'clic', 'after registro', 0);
        });
    } else {
        console.warn('WARNING: GOOGLE ANALYTICS NOT AVAILABLE');
    }
});